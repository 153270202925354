@use "../../colors" as *;
// .OnHoverReadMoreBtn {
//   border: 3px solid $btncolor;
//   color: $prytextcolor !important;
//   border-radius: 20px;
//   width: 11rem;
//   height: 2.5rem;
//   font-family: "Rubik";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
// }
.ManagedServicesRequestBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 7.5rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.TalentPlacementBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 16rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.TalentPlacementRequestBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
