// .list-font-weight {
//   font-weight: 300 !important;
// }

.fontWeight {
  font-weight: lighter !important;
  color: blue;
}

.fontFamily {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
