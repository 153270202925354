@use "../../colors" as *;
// body {
//     text-align: center;
//     box-sizing: border-box;
//     width: 100%;
//     top: 196px;
//     background: rgba(0, 164, 149, 0.07);
//     border: 1px #000000;
// }

.partnersBg {
  background-color: $lightgreenbg;
}
.heading {
  text-align: center;
}

.logo1 {
  text-align: center;
  width: 221.21px;
  //height: 136.51px;
  top: 2351px;
}

.logo2 {
  text-align: center;
  width: 275.49px;
  //height: 136.51px;
  top: 2351px;
}
