@use "../../colors" as *;

.PreviousBtn {
  box-sizing: border-box;
  text-align: center;
  width: 50px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.NextBtn {
  box-sizing: border-box;
  text-align: center;
  width: 50px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 35px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

@mixin ResourcesTitleBtn {
  border: 1px solid $btncolor;
  color: $prytextcolor;
  background-color: $prybgcolor;
  border-radius: 20px;
  width: 9rem;
  height: 1.7rem;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.ResourcesTitleBtn {
  @include ResourcesTitleBtn;
}
