* {
  box-sizing: border-box;
  font-family: "Karla", Rubik;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: visible !important;
  // overflow: auto !important;
}
