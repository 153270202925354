$prybgcolor: #ffffff;
$lightgreenbg: rgba(0, 164, 149, 0.07);
$bordercolor: #00a495;
$heroblackbg: rgba(0, 0, 0, 0.9);
$darkgreenbg: #00a495;
$managedservicestestimonialbg: rgba(16, 15, 15, 0.3);
$prytextcolor: #000;
$sectextcolor: #ffffff;
$btncolor: #821e6b;
$textbtnonhover: #ffffff;
$linkedinbtncolor: rgba(12, 97, 191, 1);
$InstagramBtn: rgba(216, 43, 117, 1);
$ShareBtn: rgba(183, 255, 65, 1);
$facebookcolorbtn: #001d87;
$testimonialCards: #404040;
$managedservicesbordercolor1: #03a062;
