.bookingform {
  color: #ef00c0;
}
.center {
  text-align: center;
  width: auto;
}

.ManagedServicesRequestForm-Container {
  border: 1px solid blac;
  margin: auto;
}

.div1::before {
  content: "";
  background-image: url(../assets/images/logo.png);
  background-size: 250px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 20%;
  background-position: center;
}

// .ManagedServicesRequestForm-Text{
//     margin-bottom: 50px;
// }
.request {
  text-align: center;
}

.submit {
  text-align: center;
  box-sizing: border-box;
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.sub {
  text-align: center;
}

.prop {
  text-align: center;
  box-sizing: border-box;
  width: 200px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}
.form-control {
  font-weight: 100;
  font-size: 0.8rem;
}
.dropdownFont {
  font-size: 0.8rem;
}
.selectoptionform {
  // font-size: 0.7rem;
  font-weight: 700;
}
.borderLine {
  outline: none;
  color: #821e6b;
}

.pink {
  color: #ef00c0;
}

.option {
  font-size: 20px !important;
}
