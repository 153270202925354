.sidecard {
  // border: none;
  width: 100vw;
}

.blogarticle {
  width: 100%;
  width: fit-content;
  // block-size: fit-content;
}

.MainCard {
  width: 100%;
  // width: fit-content;
  // block-size: fit-content;
  padding: 20px;
  position: absolute;
}
.blogCategory {
  position: fixed;
  width: 96%;
}

.blogPagE {
  width: fit-content;
}

.text-fluid1 {
  font-size: 1vw;
}

.sideCard {
  min-height: 3rem;
}

.text-fluid5 {
  font-size: 1rem;
  color: var(--white);
  line-height: 1rem;
  font-family: "Open Sans", sans-serif;
}
.text-fluid6 {
  font-size: 0.9rem;
  color: var(--white);
  line-height: 1.3rem;
  font-family: "Open Sans", sans-serif;
}
.sideBlogCards {
  width: 20rem;
}

.sideCardImg {
  // border-radius: 5rem;
  box-shadow: 0 3px 3px 3px rgba(150, 170, 180, 0.5);
}
.sidecard-date-text {
  font-size: 0.7rem;
  font-weight: 100;
}
