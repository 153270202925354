@use "../colors" as *;

.TractionsBg {
  background-color: $darkgreenbg;
}
.tractionsCardBg {
  background-color: $darkgreenbg;
  border: 3px solid $prybgcolor;
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.25);
  color: $sectextcolor;
}
