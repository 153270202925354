@use "./components/allCards/blogCards";
@use "./components/allCards/managedServicesCards";
@use "../sassfiles/pages/whoweare/aboutUs";
@use "./reset";
@use "../sassfiles/components/buttons/blogBtn" as *;
@use "../sassfiles/components/buttons/projectDeliveryBtn" as *;
@use "./font" as *;
@use "./components/allCards/testimonialCards" as *;
@use "./components/constructionpage" as *;
@use "./components/timer" as *;
@use "./pages/home" as *;
@use "../sassfiles/components/hero/bgColorandTextHero" as *;
@use "../sassfiles/components/buttons/contactBtn" as *;
@use "../sassfiles/components/buttons/eventsBtn" as *;
@use "../sassfiles/components/allCards/MediaCards";
@use "../sassfiles/components/individualPortfolioInfo";
@use "../sassfiles/pages/studio/ourModel";
@use "../sassfiles/pages/projectdelivery/projectDelivery";
@use "../sassfiles/pages/services/managedServices";
@use "../sassfiles/pages/events/events";
@use "../sassfiles/pages/services/GaaS";
@use "./components/forms/servicesForms";
@use "./components/navbar" as *;
@use "./components/constructionpage" as *;
@use "./components/timer" as *;
@use "./components/allCards/teamCards" as *;
@use "../sassfiles/pages/services/coworking" as *;
@use "./components/hero/imageandTextHero";
@use "./components/hero/videoHero";
@use "./components/forms/enquiresandSuggestionForms";
@use "./components/buttons/servicesBtn";
@use "../sassfiles/components/footer";
@use "./pages/services/managedServicesDigitalMarketing";
@use "./components/allCards/triggeredCards";
@use "./pages/insights/FAQ";
@use "./components/buttons/scrollToTop";
@use "./components/buttons/portfolioBtn";
@use "./components/blogPost";
@use "./pages/events/demoDay";
@use "./pages/events/foundersMeetup" as *;
@use "./pages/resources";
@use "./pages/services/talentPlacement" as *;
@use "./pages/digiplus" as *;

//careers
@use "./pages/careers/bdandsalesperson";

// .cursorr {
//   cursor: url(/src/assets/icons/dealmanagement.png), auto !important;
// }

// body {
//   cursor: url(/src/assets/icons/dealmanagement.png), auto;
// }
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whatsapp_float:hover .whatsapp-icon {
  color: #fff; /* Set the icon color to white on hover */
}

.whatsapp-icon {
  margin: 0;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

// //co
// .d-flex {
//   display: flex;
// }

// .text-overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   color: white; /* Adjust text color as needed */
//   background-color: rgba(
//     0,
//     0,
//     0,
//     0.5
//   ); /* Add a semi-transparent background for better contrast */
//   z-index: 1; /* Ensure text overlay stays on top */
// }

// .card-caption {
//   position: absolute;
//   bottom: 20px;
//   left: 20px;
//   color: white; /* Adjust caption text color as needed */
//   text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8); /* Add a slight shadow for better readability */
// }
