.text-decoration {
  &:hover {
    border-bottom: 0.125rem solid #821e6b;
  }
  &:focus {
    font-weight: 600;
    border-bottom: 0.125rem solid #821e6b;
  }
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
}

.border-right {
  border-right: 0.11rem solid #000;
}
