.manageSvcard {
  position: relative;
  text-align: center;
  color: white;
}

.manageCardText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
}

.testimonialImage {
  max-height: 2.5rem;
}
