@use "../../colors" as *;
.SuggestAnEventBtn {
  // border: 3px solid $btncolor;
  // color: $prybgcolor;
  // border-radius: 35px;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.LetUsKnowBtn {
  // border-radius: 118.57853698730469px;
  // border: 270px 603px 2078.42138671875px $btncolor;
  // color: $prytextcolor;
  // background-color: $prybgcolor;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.EventViewMoreBtn {
  // border: 70px 232px 640px 3228px $btncolor;
  // border-radius: 35px;
  // background-color: $prybgcolor;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  //   color: $prytextcolor;
}
.EventsRegisterBtn {
  // border: 70px 232px 640px 3228px $btncolor;
  // border-radius: 35px;
  // background-color: $prybgcolor;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 10rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  //   color: $prytextcolor;
}
.GetYourTicketBtn {
  // border: 333px 70px 1087px 2864px;
  // border-radius: 35px;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 11rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  // background-color: $btncolor;
}
.textbutton {
  text-decoration: none !important;
  color: $prytextcolor;
}
