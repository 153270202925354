@use "../colors" as *;

.SectionTwoBg {
  background-color: $lightgreenbg;
}
@mixin CardBg {
  background-color: $lightgreenbg;
  border: 3px solid $bordercolor;
}
.cardBg {
  @include CardBg;
}

.homePageText {
  font-size: 1.2rem;
  // font-weight: 600;
}
.headerText {
  text-decoration: none !important;
  color: $prytextcolor;
  &:hover {
    color: $prytextcolor;
  }
}
.textFont {
  font-size: 1.1rem;
}
.talkToUsText {
  font-weight: 400;
}
// .borderLine {
//   border: 3px solid black;
// }
.homeHeroBig {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.homeHeroBig::before {
  content: "";
  background: url("../../public/images/Homepageassets/HomepageHero.jpeg");
  // background: url(/src/assets/images/hero/homehero.jpg);
  // background-image: linear-gradient(
  //   to right,
  //   #252c3e,
  //   rgba(37, 44, 62, 0.66),
  //   rgba(37, 44, 62, 0.42),
  //   rgba(13, 22, 44, 0.1),
  //   rgba(37, 44, 62, 0)
  // );
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  // opacity: 0.6;
}
.homeHeroBig h1 {
  position: relative;
}
.homeHeroBig h3 {
  position: relative;
}
.homeHeroBig p {
  position: relative;
}
.homeHeroSmall {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.homeHeroSmall::before {
  content: "";
  background: url("../../public/images/Homepageassets/HomepageHeroSmall.jpeg");
  // background: url(/src/assets/images/hero/homehero.jpg);
  // background-image: linear-gradient(
  //   to right,
  //   #252c3e,
  //   rgba(37, 44, 62, 0.66),
  //   rgba(37, 44, 62, 0.42),
  //   rgba(13, 22, 44, 0.1),
  //   rgba(37, 44, 62, 0)
  // );
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  // opacity: 0.6;
}
.homeHeroSmall h1 {
  position: relative;
}
.homeHeroSmall h3 {
  position: relative;
}
.homeHeroSmall p {
  position: relative;
}
// HERO SECTION
.HeroSection {
  background: url("../../public/images/Homepageassets/HomepageHero.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.HeroSection::before {
  content: "";
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.678); /* Adjust gradient as needed */
  z-index: 1;
}

.HeroSectionContent {
  position: absolute;
  top: 40%;
  left: 35%;
  transform: translate(-50%, 50%);
  //   text-align: center;
  color: #fff; /* White text color */
  z-index: 2;
}
// SERVICE SECTION
.serviceSection {
  margin-top: 0;
  background-color: #f1f8ed;
}
.service-card {
  border-radius: 20px !important;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.service-body {
  height: 170px;
}

// ABOUT US SECTION
.aboutUS {
  //   margin: 0;
  background-color: #f1f8ed;
  //   width: 100vw;
  //   padding: 15px;
}

.aboutbtn {
  background-color: #fff !important;
}
.aboutbtn:hover {
  background-color: purple;
}

/*MEDIA QUERIES*/

/* Medium devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 850.34px) {
  .HeroSection {
    height: 80vh;
  }
  .HeroSection::before {
    top: 65px;
    width: 100%;
    height: 81%;
  }
  .HeroSectionContent {
    display: block;
    top: 10%;
    width: 70%;
    h1 {
      font-size: 4rem;
      line-height: 80px;
    }
  }
  // SERVICE CARD
  .service-card {
    width: 600px !important;
    margin-top: 20px;
    background: #fff !important;
    border: 1px solid white !important;
    // border-radius: 5px !important;
    // display: none !important;
  }
  .service-thumbnail {
    height: 250px !important;
    background-size: contain;
  }
}

/* Small devices (phones, less than 576px) */
@media (min-width: 340px) and (max-width: 575.98px) {
  .HeroSection {
    height: 50vh;
  }
  .HeroSection::before {
    width: 100%;
    height: 50%;
  }
  .HeroSectionContent {
    display: block;
    top: 0%;
    width: 60%;
    h1 {
      font-size: 2rem;
      line-height: 40px;
    }
  }

  .aboutImage {
    width: 200px;
  }
}
