.jd-list-items {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
}
.careerHeader {
  font-size: 1.6rem;
  font-weight: 600;
}
.careerSubHeader {
  color: #37352f;
  font-weight: 600;
}
.careerCTAfontSize {
  font-size: 1.1rem;
  font-family: "Open Sans", sans-serif;
  //   font-weight: 300;
}
