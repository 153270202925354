.button-group .btn.btn-primary {
  width: 229px;
  background: #fafafa;
  color: #a5009f;
  font-family: "Poppins", sans-serif !important;
  opacity: 1;
  transition: background 0.3s, color 0.3s;
}

.button-group .btn.btn-primary.active {
  background: #6e006a;
  color: white;
}

.button-group .btn.btn-primary:hover {
  background: #6e006a;
  color: white;
}

/* op */

.time-range {
  font-size: 0.85em;
  color: #555;
  font-style: italic;
  margin-top: 5px;
}

.new-tab-card {
  font-family: "Poppins", sans-serif !important;
}

.new-tab-card .totalprice {
  color: #6e006a;
  font-family: "Poppins", sans-serif !important;
}

.new-tab-card.card,
.price-body {
  background: #f7e1f7 !important;
  gap: 5px;
}

.card-title.h5 {
  color: #a5009f;
}

.new-tab-card hr {
  color: #a5009f;
}

.new-tab-card li {
  color: #1c1c1c;
  font-family: "Poppins", sans-serif !important;
}

.hero-section {
  background-image: url("../image/radio.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
  margin-top: -100px !important;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content {
  top: 12rem;
}

.row-content {
  margin-top: 200px !important;
}

.btn.btn-primary { 
  background-color: #6e006a;
  border: 1.46px solid #a5009f;
}

.btn.btn-primary:hover {
  background-color: #a5009f;
  color: #fff;
  border: 1.46px solid #a5009f;
}

/* #dropdown */
#dropdown-basic-button {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #6e006a;
  height: 2.7rem;
  transition: background-color 0.3s, color 0.3s;
  justify-content: center;
}

