.sidecard {
  width: 100vw;
}

.blogarticle {
  width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.MainCard {
  width: 100%;
  padding: 20px;
  position: absolute;
}

.blogCategory {
  position: fixed;
  width: 96%;
}

.blogPagE {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.text-fluid1 {
  font-size: 1vw;
}

.sideCard {
  min-height: 3rem;
}

.text-fluid5 {
  font-size: 1rem;
  color: var(--white);
  line-height: 1rem;
  font-family: "Open Sans", sans-serif;
}

.text-fluid6 {
  font-size: 0.9rem;
  color: var(--white);
  line-height: 1.3rem;
  font-family: "Open Sans", sans-serif;
}

.sideBlogCards {
  width: 20rem;
}

.sideCardImg {
  box-shadow: 0 3px 3px 3px rgba(150, 170, 180, 0.5);
}

.sidecard-date-text {
  font-size: 0.7rem;
  font-weight: 100;
}

.manageSvcard {
  position: relative;
  text-align: center;
  color: white;
}

.manageCardText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
}

.testimonialImage {
  max-height: 2.5rem;
}

.partnersBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.heading {
  text-align: center;
}

.logo1 {
  text-align: center;
  width: 221.21px;
  top: 2351px;
}

.logo2 {
  text-align: center;
  width: 275.49px;
  top: 2351px;
}

* {
  box-sizing: border-box;
  font-family: "Karla", Rubik;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: visible !important;
}

.LinkedInBtn {
  background-color: rgb(12, 97, 191);
  border-radius: 2.1875rem;
  color: white;
  width: 9rem;
  height: 2.8125rem;
}
.LinkedInBtn:hover {
  background-color: rgb(12, 97, 191);
  color: #ffffff;
}
.LinkedInBtn:focus {
  background-color: rgb(12, 97, 191);
  color: #ffffff;
}

.icon {
  margin-right: 0.5rem;
}

.InstagramBtn {
  background-color: rgb(216, 43, 117);
  border-radius: 2.1875rem;
  color: white;
  width: 9rem;
  height: 2.8125rem;
}
.InstagramBtn:hover {
  background-color: rgb(216, 43, 117);
  color: #ffffff;
}
.InstagramBtn:focus {
  background-color: rgb(216, 43, 117);
  color: #ffffff;
}

.ShareBtn {
  background-color: rgb(183, 255, 65);
  border-radius: 2.1875rem;
  color: white;
  width: 9rem;
  height: 2.8125rem;
}
.ShareBtn:hover {
  background-color: rgb(183, 255, 65);
  color: #ffffff;
}
.ShareBtn:focus {
  background-color: rgb(183, 255, 65);
  color: #ffffff;
}

.BlogReadMoreBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.BlogReadMoreBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.BlogReadMoreBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.BlogReadMoreLinkBtn {
  color: #821e6b !important;
  font-style: normal;
  font-weight: 700;
  font-size: 1.129rem;
  line-height: 1.313rem;
  text-transform: capitalize;
  color: none;
}

.SubmitCommentBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.SubmitCommentBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.SubmitCommentBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.FacebookBtn {
  border-radius: 2.1875rem;
  color: white;
  width: 9rem;
  height: 2.8125rem;
  background-color: #001d87;
  border-radius: 2.188rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Rubik", sans-serif;
}

h2 {
  font-size: 2rem;
  font-family: "Rubik", sans-serif;
}

h3 {
  font-size: 1.5rem;
  font-family: "Rubik", sans-serif;
}

h4 {
  font-size: 1.3rem;
  font-family: "Rubik", sans-serif;
}

h5 {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Rubik", sans-serif;
}

h6 {
  font-size: 0.82em;
  font-weight: 300;
  font-family: "Rubik", sans-serif;
}

p {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
}

ul li {
  font-weight: 300 !important;
  font-size: 0.98rem;
  font-family: "Open Sans", sans-serif;
}

.OnHoverReadMoreBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 8.55rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.OnHoverReadMoreBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.OnHoverReadMoreBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.LearnMoreBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 8.55rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.LearnMoreBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.LearnMoreBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.RequestForProposalBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 17rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.RequestForProposalBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.RequestForProposalBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.ProjectDeliveryViewMoreBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.ProjectDeliveryViewMoreBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.ProjectDeliveryViewMoreBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.RegisterBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.RegisterBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.RegisterBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.ManagedServicesPageTestimonialCard {
  background-color: #404040 !important;
  justify-items: right !important;
}

.linkedin {
  background-color: #fff !important;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
}

.img-fluid rounded-start {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}

article h4 {
  text-transform: uppercase;
  color: var(--clr-primary-5);
  margin-bottom: 0.25rem;
}

.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: var(--clr-grey-3);
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: var(--clr-grey-5);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: var(--clr-grey-5);
  color: var(--clr-white);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 20%;
  cursor: pointer;
  transition: var(--transition);
  padding-bottom: 30px;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background: var(--clr-primary-5);
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
.next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

.lastSlide {
  transform: translateX(-100%);
}

.nextSlide {
  transform: translateX(100%);
}

.line {
  border: 2px solid #821e6b !important;
}

.navbar {
  margin: 8px;
  color: #ffffff;
}

.constructionpage {
  max-width: 100%;
  width: fit;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.SectionTwoBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.cardBg {
  background-color: rgba(0, 164, 149, 0.07);
  border: 3px solid #00a495;
}

.homePageText {
  font-size: 1.2rem;
}

.headerText {
  text-decoration: none !important;
  color: #000;
}
.headerText:hover {
  color: #000;
}

.textFont {
  font-size: 1.1rem;
}

.talkToUsText {
  font-weight: 400;
}

.homeHeroBig {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.homeHeroBig::before {
  content: "";
  background: url("../../public/images/Homepageassets/HomepageHero.jpeg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.homeHeroBig h1 {
  position: relative;
}

.homeHeroBig h3 {
  position: relative;
}

.homeHeroBig p {
  position: relative;
}

.homeHeroSmall {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.homeHeroSmall::before {
  content: "";
  background: url("../../public/images/Homepageassets/HomepageHeroSmall.jpeg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.homeHeroSmall h1 {
  position: relative;
}

.homeHeroSmall h3 {
  position: relative;
}

.homeHeroSmall p {
  position: relative;
}

.HeroSection {
  background: url("../../public/images/Homepageassets/HomepageHero.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.HeroSection::before {
  content: "";
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.678); /* Adjust gradient as needed */
  z-index: 1;
}

.HeroSectionContent {
  position: absolute;
  top: 40%;
  left: 35%;
  transform: translate(-50%, 50%);
  color: #fff; /* White text color */
  z-index: 2;
}

.serviceSection {
  margin-top: 0;
  background-color: #f1f8ed;
}

.service-card {
  border-radius: 20px !important;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.service-body {
  height: 170px;
}

.aboutUS {
  background-color: #f1f8ed;
}

.aboutbtn {
  background-color: #fff !important;
}

.aboutbtn:hover {
  background-color: purple;
}

/*MEDIA QUERIES*/
/* Medium devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 850.34px) {
  .HeroSection {
    height: 80vh;
  }
  .HeroSection::before {
    top: 65px;
    width: 100%;
    height: 81%;
  }
  .HeroSectionContent {
    display: block;
    top: 10%;
    width: 70%;
  }
  .HeroSectionContent h1 {
    font-size: 4rem;
    line-height: 80px;
  }
  .service-card {
    width: 600px !important;
    margin-top: 20px;
    background: #fff !important;
    border: 1px solid white !important;
  }
  .service-thumbnail {
    height: 250px !important;
    background-size: contain;
  }
}
/* Small devices (phones, less than 576px) */
@media (min-width: 340px) and (max-width: 575.98px) {
  .HeroSection {
    height: 50vh;
  }
  .HeroSection::before {
    width: 100%;
    height: 50%;
  }
  .HeroSectionContent {
    display: block;
    top: 0%;
    width: 60%;
  }
  .HeroSectionContent h1 {
    font-size: 2rem;
    line-height: 40px;
  }
  .aboutImage {
    width: 200px;
  }
}
.PrivacyAndPolicyHero {
  color: rgba(0, 0, 0, 0.9);
  background-color: #f7f7f7;
}

.PrivacyAndPolicyP {
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}

.PrivacyAndPolicyP h4 {
  line-height: 50px;
}

.privatePolicyBody {
  text-align: justify;
}

@media only screen and (max-width: 405px) {
  .privatePolicyBody {
    text-align: center;
  }
}
@media only screen and (max-width: 405px) {
  .privatePolicyBody li {
    text-align: left;
  }
}
.ManagedServicesHeroText {
  color: rgba(0, 0, 0, 0.9);
  font-family: Rubik;
  font-weight: 500;
  font-size: 1.8em;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  padding: 150px 100px 0px 100px;
}

.lineHeight {
  line-height: 2.4rem;
}

.teamHeroFontSize {
  font-size: 1.2rem;
  color: #afafaf;
  text-align: center;
}

.FoundersMeetupText {
  color: #ffffff !important;
}

.FoundersMeetupBg {
  color: #000;
  background-color: rgba(0, 164, 149, 0.07);
}

.PortfolioHeroText {
  color: #ffffff !important;
}

.PortfolioHeroBg {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.9);
}

.porfolioParagraph {
  font-size: 1.3rem;
  line-height: 2rem;
}

.ReadMoreResourcesBg {
  background-color: #2400a4;
}

.talentHeroBg {
  background: rgba(0, 164, 149, 0.1);
}

.BookNowBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 10rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.BookNowBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.BookNowBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.team-button-color {
  color: #821e6b;
}
.team-button-color:hover {
  color: #821e6b;
  font-size: 1.1rem;
}

.BookaSessionBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.BookaSessionBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.BookaSessionBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.BookaFreeSessionBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.BookaFreeSessionBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.BookaFreeSessionBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.JointheStudioBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 14rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.JointheStudioBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.JointheStudioBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.JointheTeamBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 10rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.JointheTeamBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.JointheTeamBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.SubmitFormBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 7rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.SubmitFormBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.SubmitFormBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.HomeBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 6rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.HomeBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.HomeBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.ContactUsBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.ContactUsBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.ContactUsBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.DigiPlusRequestBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.DigiPlusRequestBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.DigiPlusRequestBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.SuggestAnEventBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.SuggestAnEventBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.SuggestAnEventBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.LetUsKnowBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.LetUsKnowBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.LetUsKnowBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.EventViewMoreBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.EventViewMoreBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.EventViewMoreBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.EventsRegisterBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 10rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.EventsRegisterBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.EventsRegisterBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.GetYourTicketBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 11rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.textbutton {
  text-decoration: none !important;
  color: #000;
}

.BorderOf1px {
  padding: 1px;
  border: 1px solid black;
  width: 106px;
}

.ProjectDeliveryManagedServicesCardText {
  font-size: 20px;
  color: white;
  font-weight: 900;
  margin-top: 100px;
}

.bg-pic1 {
  height: 400px;
  background-size: cover;
}

.bg-logo1 {
  height: 300px;
}

.bg-logo2 {
  height: 300px;
  background-size: cover;
  border: 1px solid black;
}

.bg-logo3 {
  height: 300px;
  background-size: cover;
  border: 1px solid black;
}

.bg-logo4 {
  height: 300px;
  background-size: cover;
  border: 1px solid black;
}

.TextAlignCenter {
  text-align: center;
}

.AllPortfoliosLogoCard {
  border: none;
}

.successStoryCardBg {
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);
}

.galleryvideos {
  width: 5rem;
  max-height: 60rem;
}

.projectdeliveryCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 340px;
  background-color: #f7e1f7;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
}

.impactSection {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.TractionsBg {
  background-color: #00a495;
}

.tractionsCardBg {
  background-color: #00a495;
  border: 3px solid #ffffff;
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.25);
  color: #ffffff;
}

.WhatWeDoBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.ourImpactBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.successStoryBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.purple-text {
  color: #821e6b;
}

.projectHero {
  height: 100vh;
  position: relative;
  background: url(../../public/images/FMU_24_Q2/demodaysix.JPG);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projectHero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(90, 88, 88, 0.1), rgba(0, 0, 0, 0.7) 50%, rgb(0, 0, 0) 100%); /* Adjust gradient as needed */
  z-index: 1;
}

/* Centered text */
.hero-text {
  position: absolute;
  top: 30%;
  text-align: center;
  color: #fff; /* White text color */
  z-index: 2;
  /* Optional styles for text */
}

.HeroButton {
  background-color: #fff !important;
  border: 2px solid #821e6b !important;
}
.HeroButton h4,
.HeroButton a {
  text-decoration: none;
  color: #821e6b !important;
  font-weight: 500 !important;
}

.HeroButton:hover {
  background-color: #821e6b !important;
}
.HeroButton:hover a {
  color: #fff !important;
}

/*For the compentency Card*/
.competency-Card {
  position: relative;
  height: 500px;
  gap: 20px;
  align-items: center;
  justify-items: center;
}

/*ENTERPRISE PAGE*/
.EnterpriceHero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.EnterpriceHero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(90, 88, 88, 0.1), rgba(0, 0, 0, 0.7) 50%, rgb(0, 0, 0) 100%); /* Adjust gradient as needed */
  z-index: 1;
}

.impactCards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 300px;
}

/*For the compentency Card*/
.competency-Card {
  position: relative;
  height: 500px;
  gap: 20px;
  align-items: center;
  justify-items: center;
}

.clients {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 10px;
}

.successStory {
  margin-top: 10px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery {
  display: flex;
  flex-direction: row;
}

.imageCaption {
  position: relative;
  top: 10px;
  text-align: center;
}

.button-controller i {
  color: #821e6b;
  font-size: 30px;
}

.impactRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.ourImpactCardBg {
  background-color: rgba(0, 164, 149, 0.07);
  padding: 10px;
  width: 300px;
}
.ourImpactCardBg p {
  font-weight: bolder;
}

.traction-text {
  font-weight: bolder;
  color: #00683e;
}

/* Media Queries */
/* Medium devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 850.34px) {
  .impactRow {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center;
    gap: 20px;
  }
  .ourImpactCardBg {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
/* Small devices (phones, less than 576px) */
@media (min-width: 310px) and (max-width: 575.98px) {
  .impactRow {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    gap: 20px;
  }
  .ourImpactCardBg {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .button-controller i {
    color: #821e6b;
    font-size: 30px;
  }
}
.howItWorksBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.cardss {
  border-width: 0em !important;
}

.cardsss {
  border-radius: 5px;
}

.managedServicesBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.cardBgg {
  background-color: #ffffff;
}

.sectionOneBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.eventsCardBg {
  background-color: rgba(0, 164, 149, 0.07);
  border: 3px solid #00a495;
}

.carouselText {
  bottom: 40%;
  height: 500px;
}

.request {
  text-align: center;
}

.submit {
  text-align: center;
  box-sizing: border-box;
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.sub {
  text-align: center;
}

.prop {
  text-align: center;
  box-sizing: border-box;
  width: 200px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.whyChooseUsBg {
  background-color: rgba(0, 164, 149, 0.07);
}

.whyChooseUsCardBg {
  border: 3px solid #00a495;
  border-radius: 60px;
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 768px) {
  .startStep {
    margin-top: 7.5rem;
  }
  .buildStep {
    margin-top: 3.75rem;
  }
  .scaleStep {
    margin-top: 0;
  }
}
.bookingform {
  color: #ef00c0;
}

.center {
  text-align: center;
  width: auto;
}

.ManagedServicesRequestForm-Container {
  border: 1px solid blac;
  margin: auto;
}

.div1::before {
  content: "";
  background-image: url(../assets/images/logo.png);
  background-size: 250px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 20%;
  background-position: center;
}

.request {
  text-align: center;
}

.submit {
  text-align: center;
  box-sizing: border-box;
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.sub {
  text-align: center;
}

.prop {
  text-align: center;
  box-sizing: border-box;
  width: 200px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.form-control {
  font-weight: 100;
  font-size: 0.8rem;
}

.dropdownFont {
  font-size: 0.8rem;
}

.selectoptionform {
  font-weight: 700;
}

.borderLine {
  outline: none;
  color: #821e6b;
}

.pink {
  color: #ef00c0;
}

.option {
  font-size: 20px !important;
}

.NavBar {
  box-shadow: none !important;
  padding-right: 0 !important;
  height: 5.5rem !important;
  border-bottom: 0 !important;
  --bs-gutter-x: 2.5rem !important;
  font-size: 18px;
  margin-top: 10px;
}

.transparent-navbar {
  background-color: transparent !important;
  border: none;
  z-index: 10;
  position: fixed;
  position: absolute;
  box-shadow: none !important;
  border-bottom: none !important;
  top: 0;
  width: 100%;
}

.transparent-navbar .navbar-brand,
.transparent-navbar .nav-link {
  color: #fff !important;
}

.transparent-navbar .nav-link:hover {
  color: #f8f9fa;
}

.transparent-navbar h5 {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}

.Nav-OffCanvas {
  display: flex !important;
  align-items: center;
}

.Nav-Dropdown {
  align-items: center;
  text-align: center;
}

.Nav-Dropdown-Items {
  padding: 15px 5px;
}

.Nav-item {
  text-align: center;
  width: 280px;
  border-bottom: 1px solid #cccccc;
  padding: 10px;
}

.link {
  color: #ef00c0;
  text-align: center;
  line-height: 3px;
}

.teamcardbody {
  border-radius: 0px;
  border-bottom: 5px solid #00a495 !important;
  border-right: 5px solid #00a495 !important;
  border-left: 5px solid #00a495 !important;
}

.Teampic {
  display: block;
  width: 100%;
}

.Text {
  line-height: 1px;
  font-size: 0.9rem;
}

.titleText {
  font-size: 0.9rem;
}

.purple {
  background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=1920&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
  padding: 0 24px;
  margin: 0;
  height: 130vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all {
  margin-top: 20px;
  gap: 2px;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(8, minmax(0, 1fr));
  height: 43rem;
  width: 41rem;
  gap: 0.063rem;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(10, minmax(0, 1fr));
  height: 900px;
  width: 200px;
  gap: 1px;
}

.c1-element1 {
  grid-row-start: 2;
  grid-row-end: 4;
  background: #ef00c0;
  text-align: center;
  color: #ffffff;
}

.coworkingGridLight {
  background: #ef00c0;
  color: #ffffff;
}

.coworkingGridDark {
  background: rgba(239, 0, 192, 0.5);
  color: #ffffff;
}

.c2-element1 {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  background: rgba(239, 0, 192, 0.5);
  text-align: center;
  color: #ffffff;
}

.c2-element2 {
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  background: rgba(239, 0, 192, 0.5);
  text-align: center;
  color: #ffffff;
}

.c2-element3 {
  grid-column-start: 2;
  grid-row-start: 5;
  grid-row-end: 7;
  background: #ef00c0;
  text-align: center;
  color: #ffffff;
}

.c3-element1 {
  grid-column-start: 3;
  grid-row-start: 3;
  grid-row-end: 5;
  background: #ef00c0;
  text-align: center;
  color: #ffffff;
}

.c3-element2 {
  grid-column-start: 3;
  grid-row-start: 5;
  grid-row-end: 7;
  background: rgba(239, 0, 192, 0.5);
  text-align: center;
  color: #ffffff;
}

.c3-element3 {
  grid-column-start: 3;
  grid-row-start: 7;
  grid-row-end: 9;
  background: #ef00c0;
  text-align: center;
  color: #ffffff;
}

.c4-element1 {
  grid-row-start: 6;
  grid-row-end: 8;
  background: rgba(239, 0, 192, 0.5);
  text-align: center;
  color: #ffffff;
}

@media only screen and (min-width: 300px) and (max-width: 929px) {
  .purple {
    background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=10&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
    padding: 0 24px;
    margin: 0;
    height: 130vh;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .all {
    margin-top: 20px;
    display: flex;
    gap: 2px;
  }
  .grid1 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
    height: 35rem;
    width: 35rem;
    gap: 0.063rem;
  }
  .grid2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    height: 35rem;
    width: 35rem;
    gap: 1px;
  }
  .c2-element1 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .c2-element2 {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 5;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .c2-element3 {
    grid-column-start: 2;
    grid-row-start: 5;
    grid-row-end: 7;
    background: #ef00c0;
    text-align: center;
    color: #ffffff;
  }
  .c3-element1 {
    grid-column-start: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    background: #ef00c0;
    text-align: center;
    color: #ffffff;
  }
  .c3-element2 {
    grid-column-start: 3;
    grid-row-start: 5;
    grid-row-end: 7;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .c3-element3 {
    grid-column-start: 3;
    grid-row-start: 7;
    grid-row-end: 9;
    background: #ef00c0;
    text-align: center;
    color: #ffffff;
  }
  .c4-element1 {
    grid-row-start: 6;
    grid-row-end: 8;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .gearBox {
    position: absolute;
    top: 120%;
    left: 75%;
  }
  .Gear {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1000px) {
  .purple {
    background-blend-mode: overlay;
    background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=1920&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
    padding: 0 24px;
    margin: 0;
    height: 130vh;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .all {
    margin-top: 20px;
    display: flex;
    gap: 2px;
  }
  .grid1 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
    height: 43rem;
    width: 41rem;
    gap: 0.063rem;
  }
  .grid2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    height: 900px;
    width: 200px;
    gap: 1px;
  }
  .c2-element1 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .c2-element2 {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 5;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .c2-element3 {
    grid-column-start: 2;
    grid-row-start: 5;
    grid-row-end: 7;
    background: #ef00c0;
    text-align: center;
    color: #ffffff;
  }
  .c3-element1 {
    grid-column-start: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    background: #ef00c0;
    text-align: center;
    color: #ffffff;
  }
  .c3-element2 {
    grid-column-start: 3;
    grid-row-start: 5;
    grid-row-end: 7;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .c3-element3 {
    grid-column-start: 3;
    grid-row-start: 7;
    grid-row-end: 9;
    background: #ef00c0;
    text-align: center;
    color: #ffffff;
  }
  .c4-element1 {
    grid-row-start: 6;
    grid-row-end: 8;
    background: rgba(239, 0, 192, 0.5);
    text-align: center;
    color: #ffffff;
  }
  .gearBox {
    position: absolute;
    top: 120%;
    left: 75%;
  }
}
.change {
  background-color: #a5009f !important;
}

.letter {
  color: #fff !important;
}

.bi-check-circle {
  color: #ef00c0;
}

.Pin {
  border-color: #a5009f;
  text-align: center;
}

.Pinner {
  border: 3px solid;
  border-bottom-color: #a5009f;
}

.finish {
  border-right-color: black;
  border-left-color: white;
}

.Pinish {
  border-right-color: aliceblue;
  border-left-color: #a5009f;
}

.pink {
  background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=1920&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
  text-align: center;
  height: 100vh;
}

.short {
  color: white;
  padding: 4rem;
}

.gearBox {
  position: absolute;
  top: 120%;
  left: 75%;
}

.DailyandWeekendBundleBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 19rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.DailyandWeekendBundleBtn:active {
  background-color: #a5009f;
  color: #ffffff;
}
.DailyandWeekendBundleBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.MeetingRoomBundleBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 17rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.MeetingRoomBundleBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.MeetingRoomBundleBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.Gear {
  color: #ffffff;
}

.bgImg {
  width: 100%;
  height: 500px;
  background-image: url(https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&w=600);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bgtext {
  color: rgb(255, 255, 255);
}

.bgimage h5 {
  color: white;
  text-shadow: 2px 2px #333;
}

.homeHeroBg {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.homeHeroBg::before {
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;
}

.homeHeroBg h1 {
  position: relative;
}

.homeHeroBg h2 {
  position: relative;
}

.homeHeroText {
  font-size: 4rem;
  font-weight: 300;
}

.GaaSHeroText {
  font-size: 3.5rem;
  font-weight: 300;
}

.projectDeliveryHeroText {
  font-size: 3.5rem;
  font-weight: 300;
}

.homeHeroTextTwo {
  font-size: 3rem;
  font-weight: 300;
}

@media only screen and (max-width: 440px) {
  .homeHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 800px) and (min-width: 441px) {
  .homeHeroBg {
    height: 60vh;
  }
}
.callToAction {
  position: relative;
}

@media only screen and (max-width: 440px) {
  .GaaSHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 800px) and (min-width: 441px) {
  .GaaSHeroBg {
    height: 60vh;
  }
}
.EventsHeroBg {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.EventsHeroBg::before {
  content: "";
  background: url(/src/assets/images/hero/eventhero.jpg);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.EventsHeroBg h2 {
  position: relative;
}

.EventsHeroBg h3 {
  position: relative;
}

@media only screen and (max-width: 440px) {
  .EventsHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 800px) and (min-width: 441px) {
  .EventsHeroBg {
    height: 60vh;
  }
}
.CoworkingHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 80vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
}

.CoworkingHeroBg {
  background: url(/src/assets/images/hero/coworkinghero.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 80vh;
  width: 100%;
}

.CoworkingHeroBg h1 {
  position: relative;
}

.CoworkingHeroBg h3 {
  position: relative;
}

.BookNow {
  position: relative;
}

.CoworkingOffersBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.7;
}

.CoworkingOffersBg {
  background: url(/src/assets/images/hero/coworkingoffersbg.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 300%;
  width: 100%;
}

.CoworkingOffersBg .coworkingGrid {
  position: relative;
}

.CoworkingOffersBg .coworkingGrid {
  position: relative;
}

.CoworkingTestmonialBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;
}

.CoworkingTestmonialBg {
  background: url(/src/assets/images/hero/coworkingtestimonialbg.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 300%;
  width: 100%;
}

.CoworkingTestmonialBg h3 {
  position: relative;
}

.coworkingBookBtn,
.coworkingBookText {
  position: relative;
  line-height: 35px;
}

.CoTractionsBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;
}

.CoTractionsBg {
  background: url(/src/assets/images/talentplacement/tractions.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 300%;
  width: 100%;
}

.CoTractionsBg p,
h3,
h2 {
  position: relative;
}

.GaaSHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;
}

.GaaSHeroBg {
  background: url(/src/assets/images/founders-MeetUp.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.GaaSHeroBg h2 {
  position: relative;
  line-height: 70px;
}

.GaaSHeroBg h3 {
  position: relative;
  line-height: 45px;
  font-size: 2rem !important;
}

.Herodemodayy::before {
  content: "";
  position: absolute;
  height: 90vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.Herodemoday {
  background: url(/src/assets/images/founders-MeetUp.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 80vh;
  width: 100%;
}

.Herodemoday h2 {
  position: relative;
  line-height: 40px;
}

.Herodemoday h3 {
  position: relative;
  line-height: 25px;
  font-size: 1.1rem !important;
}

.DemoDayGalleryHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.7;
}

.DemoDayGalleryHeroBg {
  background: url(/src/assets/images/hero/demodayhero.JPG);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.DemoDayGalleryHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.DemoDayGalleryHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.ManagedServicesDigitalMargetingHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
}

.ManagedServicesDigitalMargetingHeroBg {
  background: url(/src/assets/images/hero/digitalmarketinghero.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.ManagedServicesDigitalMargetingHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.ManagedServicesDigitalMargetingHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.ManagedServicesLegalHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.6;
}

.ManagedServicesLegalHeroBg {
  background: url(/src/assets/images/hero/legal.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.ManagedServicesLegalHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.ManagedServicesLegalHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.ManagedServicesTechHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.7;
}

.ManagedServicesTechHeroBg {
  background: url(/src/assets/images/hero/techhero.jpeg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.ManagedServicesTechHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.ManagedServicesTechHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.ManagedServicesAccountingHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 80vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.7;
}

.ManagedServicesAccountingHeroBg {
  background: url(/src/assets/images/hero/acc.jpeg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 80vh;
  width: 100%;
}

.ManagedServicesAccountingHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.ManagedServicesAccountingHeroBg h3 {
  position: relative;
  line-height: 35px;
}

@media only screen and (max-width: 990px) {
  .ManagedServicesAccountingHeroBgg::before,
.ManagedServicesTechHeroBgg::before,
.ManagedServicesLegalHeroBgg::before,
.ManagedServicesDigitalMargetingHeroBgg::before,
.GaaSHeroBgg::before,
.Herodemodayy::before,
.CoworkingHeroBgg::before {
    height: 22.5rem;
  }
  .ManagedServicesAccountingHeroBg,
.ManagedServicesTechHeroBg,
.ManagedServicesLegalHeroBg,
.ManagedServicesDigitalMargetingHeroBg,
.GaaSHeroBg,
.Herodemoday,
.CoworkingHeroBg {
    height: 22.5rem;
  }
}
.ourModelHeroBg {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.ourModelHeroBg::before {
  content: "";
  background: url(/src/assets/images/hero/ourmodelhero.jpg);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1;
}

.ourModelHeroBg h2 {
  position: relative;
}

.ourModelHeroBg h3 {
  position: relative;
}

.ourModelHeroBg h4 {
  position: relative;
}

@media only screen and (max-width: 440px) {
  .ourModelHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 575px) and (min-width: 441px) {
  .ourModelHeroBg {
    height: 60vh;
  }
}
.projectDeliveryHeroBg {
  background: url(/src/assets/images/hero/projectdelivery.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

.NewTalentPlacementHeroBg {
  background: url(/src/assets/images/hero/talentplacement.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

.NewTalentPlacementHeroText {
  font-size: 3rem;
  font-weight: 300;
}

.NewTalentPlacementHeroBg h1 {
  position: relative;
}

.NewTalentPlacementHeroBg h2 {
  position: relative;
}

.NewTalentPlacementHeroBg p {
  position: relative;
}

@media only screen and (max-width: 440px) {
  .NewTalentPlacementHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 650px) and (min-width: 441px) {
  .NewTalentPlacementHeroBg {
    height: 60vh;
  }
}
.talentPlacementBtn {
  position: relative;
}

.gradientBg {
  background: rgba(0, 0, 0, 0.678);
}

.overlayBg {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 6;
  left: 0;
}

.projectDeliveryHeroBg h1 {
  position: relative;
}

.projectDeliveryHeroBg h2 {
  position: relative;
}

@media only screen and (max-width: 440px) {
  .projectDeliveryHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 650px) and (min-width: 441px) {
  .projectDeliveryHeroBg {
    height: 60vh;
  }
}
.piggeryTrainingHeroBg {
  background: url(/src/assets/images/hero/piggerytraininghero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

@media only screen and (max-width: 440px) {
  .piggeryTrainingHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 575px) and (min-width: 441px) {
  .piggeryTrainingHeroBg {
    height: 60vh;
  }
}
.piggeryTrainingHeroBg h1 {
  position: relative;
}

.piggeryTrainingHeroBg h2 {
  position: relative;
}

.GIZHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}

.GIZHeroBg {
  background: url(/src/assets/images/hero/GIZhero.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.GIZHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.GIZHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.LSETFHeroBg {
  background: url(/src/assets/images/hero/lsetfhero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

@media only screen and (max-width: 440px) {
  .LSETFHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 575px) and (min-width: 441px) {
  .LSETFHeroBg {
    height: 60vh;
  }
}
.LSETFHeroBg h1 {
  position: relative;
}

.LSETFHeroBg h2 {
  position: relative;
}

.hackathonHeroBg {
  background: url(/src/assets/images/hero/hackathon.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

@media only screen and (max-width: 440px) {
  .hackathonHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 575px) and (min-width: 441px) {
  .hackathonHeroBg {
    height: 60vh;
  }
}
.hackathonHeroBg h1 {
  position: relative;
}

.hackathonHeroBg h2 {
  position: relative;
}

.ITFHeroBg {
  background: url(/src/assets/images/hero/ITF.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

@media only screen and (max-width: 440px) {
  .ITFHeroBg {
    height: 50vh;
  }
}
@media only screen and (max-width: 575px) and (min-width: 441px) {
  .ITFHeroBg {
    height: 60vh;
  }
}
.ITFHeroBg h1 {
  position: relative;
}

.ITFHeroBg h2 {
  position: relative;
}

.heroTextColor {
  color: #ffffff;
}

.cool {
  width: auto;
  height: 50vh;
  background-image: url(https://media.macphun.com/img/uploads/customer/blog/347/15361508215b8fcd25b13f46.53184133.jpg?q=85&w=1680);
  background-repeat: no-repeat;
  background-size: cover;
}

.stop {
  color: rgb(255, 255, 255);
}

.ResourcesHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 80vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.ResourcesHeroBg {
  background: url(/src/assets/images/resources/resourceshero.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 80vh;
  width: 100%;
}

.ResourcesHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.ResourcesHeroBg h3 {
  position: relative;
  line-height: 35px;
}

@media only screen and (max-width: 440px) {
  .ResourcesHeroBg {
    height: 80vh;
  }
}
@media only screen and (max-width: 800px) and (min-width: 441px) {
  .ResourcesHeroBg {
    height: 80vh;
  }
}
.VideoResourceHeroBgg::before {
  content: "";
  position: absolute;
  height: 70vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.VideoResourceHeroBg {
  background: url(/src/assets/images/resources/resourceshero.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 70vh;
  width: 100%;
}

.VideoResourceHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.VideoResourceHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.FUTAHeroBg {
  background: rgba(0, 164, 149, 0.44);
  background-blend-mode: darken;
}

.spaceImg {
  border-radius: 20px;
  background: url(/src/assets/images/coworking/EightGearHub.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.hubOneImg {
  border-radius: 20px;
  background: url(/src/assets/images/coworking/HubOne.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.innkeeperHackerSpaceImg {
  border-radius: 20px;
  background: url(/src/assets/images/coworking/Innkeeper.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.spaceImgCard {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin-bottom: 0.7rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.spaceTitle {
  color: #821e6b;
}

.BGG {
  background-color: rgba(0, 164, 149, 0.07);
}

.DigiPlusPageHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 80vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

@media only screen and (min-width: 650px) {
  .DigiPlusPageHeroBg {
    background: url(/src/assets/images/hero/digiplushero.png);
    background-size: cover;
    position: relative;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 80vh;
    width: 100%;
  }
}
@media only screen and (max-width: 650px) {
  .DigiPlusPageHeroBg {
    background: url(/src/assets/images/hero/digiplusherosm.png);
    background-size: cover;
    position: relative;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 80vh;
    width: 100%;
  }
}
.DigiPlusPageHeroBg h1 {
  position: relative;
}

.DigiPlusPageHeroBg h2 {
  position: relative;
}

.AboutPageHeroBg p {
  position: relative;
}

.DigiPlusPageHeroBg .DigiPlusRequestBtn {
  position: relative;
}

video {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  position: relative;
  height: 100vh;
  text-align: center;
  justify-content: center;
}

.contactUsTitle {
  text-align: center;
  color: #821e6b;
}

.submit {
  text-align: center;
  box-sizing: border-box;
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.sub {
  text-align: center;
}

.prop {
  text-align: center;
  box-sizing: border-box;
  width: 200px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}

.dame {
  border-color: blue;
}

.ManagedServicesRequestBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 7.5rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.ManagedServicesRequestBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.ManagedServicesRequestBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.TalentPlacementBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 16rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.TalentPlacementBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.TalentPlacementBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.TalentPlacementRequestBtn {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.TalentPlacementRequestBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.TalentPlacementRequestBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.ul_top_hypers {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.iconsize {
  font-size: 10px;
  color: #00a495;
  height: 100%;
  overflow: visible;
}

#iconsize {
  font-size: 10px;
  color: #00a495;
  height: 100%;
  overflow: visible;
}

.iconsize2 {
  font-size: 1.4rem;
  color: #00a495;
}

.mobile-icon {
  list-style-type: none;
}

.md-footer-line {
  width: 98vw;
}

.newsLetterUnderline {
  width: 15vw;
}

.footerLogo {
  width: 4.2vw;
}

.footerLogo2 {
  width: 3rem;
}

.footerText {
  text-decoration: none;
  color: black;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media only screen and (max-width: 1024px) {
  .footerText {
    font-size: 0.75rem;
  }
}

.icons-for-footer {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .footerIcon {
    font-size: 0.75rem;
  }
}

.footerFormField {
  outline: none;
  font-size: 13px;
  font-weight: lighter;
}

.hrForBigScreen {
  width: 17vw;
}

.firstSignUp {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.secondSignUp {
  font-size: 1.5vw;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.thirdSignUp {
  font-size: 0.8rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.emailLink {
  text-decoration: none;
  color: black;
}

.text-fluid {
  font-size: 1.1vw;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
}

.footerTextDecoration {
  text-decoration: none;
  color: #000;
}

.inputSizing {
  width: 18rem;
  height: 4.5rem;
}

.subscribeButton {
  border: 3px solid #821e6b;
  color: #000;
  border-radius: 20px;
  width: 8.5rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.subscribeButton:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.subscribeButton:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.addressText {
  color: #821e6b;
}

.text-fluid3 {
  font-size: 0.75rem;
}

.signupText {
  font-size: 0.8rem;
}

@media only screen and (max-width: 1024px) {
  .text-fluid3 {
    font-size: 0.75rem;
  }
  .signupText {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 768px) {
  .text-fluid3 {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 480px) {
  .signupText {
    font-size: 2rem;
  }
  .signupText .text-fluid3 {
    font-size: 0.8rem;
  }
  .signupText .footer__icons {
    padding-left: 0rem !important;
    padding-left: 0rem;
  }
  .signupText .remove__ml {
    margin-left: 0rem !important;
    padding-left: 0rem !important;
  }
  .signupText ul.listStyling {
    list-style-type: none !important;
    padding-left: 0rem !important;
    margin-left: 0rem !important;
  }
  .listStyling > li {
    list-style-type: none !important;
    padding-left: 0rem !important;
    margin-left: 0rem !important;
  }
}
.footer-links {
  border-bottom: 1px solid #00a495;
}
.footer-links ul {
  padding: 0 !important;
}

.managedServicesSection {
  background-color: rgb(250, 248, 248);
}

.cards {
  text-align: center;
}

.containerm {
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdvisoryImage {
  overflow: hidden;
  height: 300px;
  width: 300px;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  background-color: #555;
}

.AdvisoryImage:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background-color: gray;
  opacity: 0.8;
  border-radius: 50%;
  transition: 0.8s;
}

.AdvisoryImage:hover:after {
  transform: translate(-50%, -50%);
}

.AdvisoryImage:hover .AdvisoryIcon {
  transform: translate(-50%, -50%) scale(1.3);
}

.AdvisoryIcon {
  position: absolute;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.8s;
}

.AdvisoryName {
  position: absolute;
  text-align: center;
  line-height: 90%;
  font-size: small;
  font-weight: bold;
  z-index: 2;
  top: 75%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryName {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.AdvisoryPosition {
  position: absolute;
  text-align: center;
  font-size: small;
  z-index: 2;
  top: 83%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryPosition {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.AdvisoryLink {
  position: absolute;
  text-align: center;
  font-size: xx-small;
  z-index: 2;
  top: 92%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryLink {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.ProjectDeliveryImage {
  overflow: hidden;
  height: 300px;
  width: 300px;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  background-color: #555;
}

.ProjectDeliveryImage:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background-color: gray;
  opacity: 0.8;
  border-radius: 50%;
  transition: 0.8s;
}

.ProjectDeliveryImage:hover:after {
  transform: translate(-50%, -50%);
}

.ProjectDeliveryImage:hover .AdvisoryIcon {
  transform: translate(-50%, -50%) scale(1.3);
}

.ProjectDeliveryImage:hover .AdvisoryPosition {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.ProjectDeliveryName {
  position: absolute;
  text-align: center;
  line-height: 90%;
  font-size: small;
  font-weight: bold;
  z-index: 2;
  top: 75%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.ProjectDeliveryImage:hover .ProjectDeliveryName {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.ProjectDeliveryLink {
  position: absolute;
  text-align: center;
  font-size: xx-small;
  z-index: 2;
  top: 92%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.8s;
  transition-timing-function: ease-in;
}

.ProjectDeliveryImage:hover .ProjectDeliveryLink {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.faq {
  background-color: #00a495;
}

.Acc {
  color: black;
}

.scrolltotopBtn {
  position: fixed;
  width: 100%;
  left: 47%;
  bottom: 225px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #03a062;
}

.PreviousBtn {
  box-sizing: border-box;
  text-align: center;
  width: 50px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 30px;
}
.PreviousBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.PreviousBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.NextBtn {
  box-sizing: border-box;
  text-align: center;
  width: 50px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #821e6b;
  border-radius: 35px;
}
.NextBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.NextBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

.ResourcesTitleBtn {
  border: 1px solid #821e6b;
  color: #000;
  background-color: #ffffff;
  border-radius: 20px;
  width: 9rem;
  height: 1.7rem;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
}
.ResourcesTitleBtn:hover {
  background-color: #821e6b;
  color: #ffffff;
}
.ResourcesTitleBtn:focus {
  background-color: #821e6b;
  color: #ffffff;
}

header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 29vh;
  color: white;
}

.blogBody {
  text-align: center;
}

.blogImage {
  width: 50vh;
}

.textLinkColor {
  color: #821e6b;
}

.fontWeight {
  font-weight: lighter !important;
  color: blue;
}

.fontFamily {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.text-decoration {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
}
.text-decoration:hover {
  border-bottom: 0.125rem solid #821e6b;
}
.text-decoration:focus {
  font-weight: 600;
  border-bottom: 0.125rem solid #821e6b;
}

.border-right {
  border-right: 0.11rem solid #000;
}

.detailHeroBg {
  background-color: #00a495;
  height: 15rem;
}

.detailBg {
  background-color: #d9d9d9;
}

.briefCard {
  height: 15rem;
  margin-top: 10rem;
}

.briefNg {
  margin-bottom: 10rem;
}

.Bmcdiv {
  margin-top: 20rem;
}

.detailsCard {
  height: 15rem;
}

.resourceReadMoreLink {
  color: #000;
}
.resourceReadMoreLink:hover {
  color: #0d6efd;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s;
}

.slider2 {
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slider2::before,
.slider2::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider2::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider2::before {
  left: 0;
  top: 0;
}

.slider2 .slide-track2 {
  -webkit-animation: scroll 45s linear infinite;
  animation: scroll 45s linear infinite;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.slider2 .slide2 {
  height: 100px;
  display: flex;
}

.slider2 .slide2 img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: auto;
  margin: auto;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    /* scroll only 100% of the total width */
    transform: translateX(-50%);
  }
}
.talentPlacementCard {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 40px 40px;
}

.eightGearImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/eightgear.png);
  background-size: cover;
  position: relative;
  height: 30rem;
  width: 100%;
}

.FUTAImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/futa.png);
  background-size: cover;
  position: relative;
  height: 30rem;
  width: 100%;
}

.sterlingImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/sterling.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.innkeeperImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/innkeeper.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.pearlMutualImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/pearlmutual.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.smedanImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/smedan.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.imgCardBg {
  bottom: 10px;
  left: 50%;
  width: 20rem;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin-bottom: 0.7rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.keyBoxShadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.jd-list-items {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Open Sans", sans-serif;
}

.careerHeader {
  font-size: 1.6rem;
  font-weight: 600;
}

.careerSubHeader {
  color: #37352f;
  font-weight: 600;
}

.careerCTAfontSize {
  font-size: 1.1rem;
  font-family: "Open Sans", sans-serif;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp_float:hover .whatsapp-icon {
  color: #fff; /* Set the icon color to white on hover */
}

.whatsapp-icon {
  margin: 0;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
/*# sourceMappingURL=styles.css.map */