@use "../../colors" as *;
@use "../../font" as *;

.purple {
  background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=1920&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
  padding: 0 24px;
  margin: 0;
  height: 130vh;
  // color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.all {
  margin-top: 20px;
  // display: flex;
  gap: 2px;
}
.grid1 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(8, minmax(0, 1fr));
  height: 43rem;
  width: 41rem;
  gap: 0.063rem;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(10, minmax(0, 1fr));
  height: 900px;
  width: 200px;
  gap: 1px;
}
.c1-element1 {
  grid-row-start: 2;
  grid-row-end: 4;
  background: #ef00c0;
  text-align: center;
  color: $prybgcolor;
}
.coworkingGridLight {
  // grid-row-start: 2;
  // grid-row-end: 4;
  background: #ef00c0;
  color: $sectextcolor;
}
.coworkingGridDark {
  background: rgba(239, 0, 192, 50%);
  color: $sectextcolor;
}
.c2-element1 {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  background: rgba(239, 0, 192, 50%);
  text-align: center;
  color: $prybgcolor;
}
.c2-element2 {
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  background: rgba(239, 0, 192, 50%);
  text-align: center;
  color: $prybgcolor;
}
.c2-element3 {
  grid-column-start: 2;
  grid-row-start: 5;
  grid-row-end: 7;
  background: #ef00c0;
  text-align: center;
  color: $prybgcolor;
}
.c3-element1 {
  grid-column-start: 3;
  grid-row-start: 3;
  grid-row-end: 5;
  background: #ef00c0;
  text-align: center;
  color: $prybgcolor;
}
.c3-element2 {
  grid-column-start: 3;
  grid-row-start: 5;
  grid-row-end: 7;
  background: rgba(239, 0, 192, 50%);
  text-align: center;
  color: $prybgcolor;
}
.c3-element3 {
  grid-column-start: 3;
  grid-row-start: 7;
  grid-row-end: 9;
  background: #ef00c0;
  text-align: center;
  color: $prybgcolor;
}
.c4-element1 {
  grid-row-start: 6;
  grid-row-end: 8;
  background: rgba(239, 0, 192, 50%);
  text-align: center;
  color: $prybgcolor;
}
// @media only screen and (max-width: 930px) {
//   .purple {
//     background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=10&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
//     padding: 0 24px;
//     margin: 0;
//     height: 130vh;
//     width: 50vh;
//     // color: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .all {
//     margin-top: 20px;
//     display: flex;
//     gap: 2px;
//   }
//   .grid1 {
//     display: grid;
//     grid-template-columns: repeat(3, minmax(0, 1fr));
//     grid-template-rows: repeat(8, minmax(0, 1fr));
//     height: 43rem;
//     width: 41rem;
//     gap: 0.063rem;
//   }
//   .grid2 {
//     display: grid;
//     grid-template-columns: repeat(1, minmax(0, 1fr));
//     grid-template-rows: repeat(10, minmax(0, 1fr));
//     height: 900px;
//     width: 200px;
//     gap: 1px;
//   }
//   .c1-element1 {
//     grid-row-start: 2;
//     grid-row-end: 4;
//     background: #ef00c0;
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c2-element1 {
//     grid-column-start: 2;
//     grid-row-start: 1;
//     grid-row-end: 3;
//     background: rgba(239, 0, 192, 50%);
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c2-element2 {
//     grid-column-start: 2;
//     grid-row-start: 3;
//     grid-row-end: 5;
//     background: rgba(239, 0, 192, 50%);
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c2-element3 {
//     grid-column-start: 2;
//     grid-row-start: 5;
//     grid-row-end: 7;
//     background: #ef00c0;
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c3-element1 {
//     grid-column-start: 3;
//     grid-row-start: 3;
//     grid-row-end: 5;
//     background: #ef00c0;
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c3-element2 {
//     grid-column-start: 3;
//     grid-row-start: 5;
//     grid-row-end: 7;
//     background: rgba(239, 0, 192, 50%);
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c3-element3 {
//     grid-column-start: 3;
//     grid-row-start: 7;
//     grid-row-end: 9;
//     background: #ef00c0;
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .c4-element1 {
//     grid-row-start: 6;
//     grid-row-end: 8;
//     background: rgba(239, 0, 192, 50%);
//     text-align: center;
//     color: $prybgcolor;
//   }
//   .gearBox {
//     position: absolute;
//     top: 120%;
//     left: 75%;
//   }
//   .Gear {
//     font-size: 1rem;
//   }
// }
@media only screen and (min-width: 300px) and (max-width: 929px) {
  .purple {
    background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=10&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
    padding: 0 24px;
    margin: 0;
    height: 130vh;
    width: 70%;
    // color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .all {
    margin-top: 20px;
    display: flex;
    gap: 2px;
  }
  .grid1 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
    height: 35rem;
    width: 35rem;
    gap: 0.063rem;
  }
  .grid2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    height: 35rem;
    width: 35rem;
    gap: 1px;
  }
  // .c1-element1 {
  //   grid-row-start: 2;
  //   grid-row-end: 4;
  //   background: #ef00c0;
  //   text-align: center;
  //   color: $prybgcolor;
  // }
  .c2-element1 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .c2-element2 {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 5;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .c2-element3 {
    grid-column-start: 2;
    grid-row-start: 5;
    grid-row-end: 7;
    background: #ef00c0;
    text-align: center;
    color: $prybgcolor;
  }
  .c3-element1 {
    grid-column-start: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    background: #ef00c0;
    text-align: center;
    color: $prybgcolor;
  }
  .c3-element2 {
    grid-column-start: 3;
    grid-row-start: 5;
    grid-row-end: 7;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .c3-element3 {
    grid-column-start: 3;
    grid-row-start: 7;
    grid-row-end: 9;
    background: #ef00c0;
    text-align: center;
    color: $prybgcolor;
  }
  .c4-element1 {
    grid-row-start: 6;
    grid-row-end: 8;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .gearBox {
    position: absolute;
    top: 120%;
    left: 75%;
  }
  .Gear {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1000px) {
  .purple {
    background-blend-mode: overlay;
    background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=1920&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
    padding: 0 24px;
    margin: 0;
    height: 130vh;
    width: auto;
    // color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .all {
    margin-top: 20px;
    display: flex;
    gap: 2px;
  }
  .grid1 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
    height: 43rem;
    width: 41rem;
    gap: 0.063rem;
  }
  .grid2 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    height: 900px;
    width: 200px;
    gap: 1px;
  }
  // .c1-element1 {
  //   grid-row-start: 2;
  //   grid-row-end: 4;
  //   background: #ef00c0;
  //   text-align: center;
  //   color: $prybgcolor;
  // }
  .c2-element1 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .c2-element2 {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 5;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .c2-element3 {
    grid-column-start: 2;
    grid-row-start: 5;
    grid-row-end: 7;
    background: #ef00c0;
    text-align: center;
    color: $prybgcolor;
  }
  .c3-element1 {
    grid-column-start: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    background: #ef00c0;
    text-align: center;
    color: $prybgcolor;
  }
  .c3-element2 {
    grid-column-start: 3;
    grid-row-start: 5;
    grid-row-end: 7;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .c3-element3 {
    grid-column-start: 3;
    grid-row-start: 7;
    grid-row-end: 9;
    background: #ef00c0;
    text-align: center;
    color: $prybgcolor;
  }
  .c4-element1 {
    grid-row-start: 6;
    grid-row-end: 8;
    background: rgba(239, 0, 192, 50%);
    text-align: center;
    color: $prybgcolor;
  }
  .gearBox {
    position: absolute;
    top: 120%;
    left: 75%;
  }
}

.change {
  background-color: #a5009f !important;
}
.letter {
  color: #fff !important;
}
.bi-check-circle {
  color: #ef00c0;
}
.Pin {
  border-color: #a5009f;
  text-align: center;
}
.Pinner {
  border: 3px solid;
  border-bottom-color: #a5009f;
}
.finish {
  border-right-color: black;
  border-left-color: #ffff;
}
.Pinish {
  border-right-color: aliceblue;
  border-left-color: #a5009f;
}
.pink {
  background-image: url(https://blog.liquidspace.com/hs-fs/hubfs/Imported_Blog_Media/7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg?width=1920&height=1200&name=7720a5e7-6ef7-41e2-92a9-62edd9eab626-1.jpeg);
  text-align: center;
  height: 100vh;
}
.short {
  color: #ffff;
  // margin: 8rem;
  padding: 4rem;
}
.gearBox {
  position: absolute;
  top: 120%;
  left: 75%;

  // text-align: end;
  // line-height: 2rem;
  // margin-top: -0.897rem;
  // padding: 1rem;
  // margin-left: auto !important;
  // margin-right: 0px !important;
  // vertical-align: baseline;
}
.DailyandWeekendBundleBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 19rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:active {
    background-color: #a5009f;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.MeetingRoomBundleBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 17rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $prybgcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $prybgcolor;
  }
}
.Gear {
  color: $prybgcolor;
}

// .puntin {
//   margin-top: 1.5rem;
// }
// .purple {
//   background-image: url(https://www.viewsonic.com/library/wp-content/uploads/2019/04/Small-Conference-Room-Called-Huddle-Space-6-c.jpg) !important;
//   height: 100vh;
//   width: 192vh;
//   display: grid;
//   grid-template-rows:300px 100px 500px 700px;
//   grid-template-columns: 200px 200px 200px 100px;
// }
// .firstsall {
//   background-color: rgba(239, 0, 192, 100%);
//   width: 160px;
//   height: 160px;
// }
// .line1 {
//   grid-template-columns: 100px 100px 100px;
// }
// .Alarm {
//   color: $prybgcolor;
// }
// .CoffeeandTea {
//   // grid-row-start: 1;
//   // grid-row-end: 2;
//   width: 160px;
//   height: 160px;
//   background-color: rgba(239, 0, 192, 50%);
//   color: $prybgcolor;
// }
// .communityacess {
//   width: 160px;
//   height: 160px;
//   // grid-row-start: 3;
//   // grid-row-end: 4;
//   background-color: rgba(239, 0, 192, 50%);
//   color: $prybgcolor;
// }
// .LoungeArea {
//   width: 160px;
//   height: 160px;
//   // grid-row-start: 5;
//   // grid-row-end: 6;
//   background-color: rgba(239, 0, 192, 100%);
//   color: $prybgcolor;
// }
// .DemoDay {
//   width: 160px;
//   height: 160px;
//   // grid-row-start: 3;
//   // grid-row-end: 4;
//   background-color: rgba(239, 0, 192, 100%);
//   color: $prybgcolor;
// }
// .QualityFurnishing {
//   width: 160px;
//   height: 160px;
//   // grid-row-start: 5;
//   // grid-row-end: 6;
//   background-color: rgba(239, 0, 192, 50%);
//   color: $prybgcolor;
// }
// .Conferenceroom {
//   width: 160px;
//   height: 160px;
//   // grid-row-start: 7;
//   // grid-row-end: 8;
//   background-color: rgba(239, 0, 192, 100%);
//   color: $prybgcolor;
// }
// .InternetService {
//   width: 160px;
//   height: 160px;
//   // grid-row-start: 7;
//   // grid-row-end: 9;
//   background-color: rgba(239, 0, 192, 50%);
//   color: $prybgcolor;
// }
