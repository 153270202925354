@use "../../sassfiles/components/buttons/contactBtn" as *;

@mixin NavBar {
    font-size: 18px;

    margin-top: 10px;
}

.NavBar {
    box-shadow: none !important;
    padding-right: 0 !important;
    height: 5.5rem !important;
    border-bottom: 0 !important;
    --bs-gutter-x: 2.5rem !important;

    @include NavBar;
}

// For a transparent Navbar
.transparent-navbar {
    background-color: transparent !important;
    border: none;
    z-index: 10; // Ensure the navbar is on top of other elements
    position: fixed;
    position: absolute;
    box-shadow: none !important;
    border-bottom: none !important;
    top: 0;
    width: 100%;
}

.transparent-navbar .navbar-brand,
.transparent-navbar .nav-link {
    color: #fff !important; // Set the text color as needed
}

.transparent-navbar .nav-link:hover {
    color: #f8f9fa; // Set the hover color as needed
}

// .JointheStudioBtn:hover {
//   background-color: #821e6b;
// }

.transparent-navbar h5 {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}

// NEW NAVBAR
.Nav-OffCanvas {
    display: flex !important;
    align-items: center;
}

.Nav-Dropdown {
    align-items: center;
    text-align: center;
}

.Nav-Dropdown-Items {
    padding: 15px 5px;
}
.Nav-item {
    text-align: center;
    width: 280px;
    border-bottom: 1px solid #cccccc;
    padding: 10px;
}
