@use "../../colors" as *;

.PrivacyAndPolicyHero {
  color: $heroblackbg;
  background-color: #f7f7f7;
}

.PrivacyAndPolicyP {
  // text-align: justify !important;
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  // color: #828282;
}
.PrivacyAndPolicyP h4 {
  line-height: 50px;
}
.privatePolicyBody {
  text-align: justify;
  // word-break: break-word;
}
@media only screen and (max-width: 405px) {
  .privatePolicyBody {
    text-align: center;
    // word-break: break-word;
  }
}
@media only screen and (max-width: 405px) {
  .privatePolicyBody li {
    text-align: left;
    // word-break: break-word;
  }
}
@mixin ManagedServicesHeroText {
  color: $heroblackbg;
  font-family: Rubik;
  font-weight: 500;
  font-size: 1.8em;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.ManagedServicesHeroText {
  @include ManagedServicesHeroText;
  padding: 150px 100px 0px 100px;
}
.lineHeight {
  line-height: 2.4rem;
}
.teamHeroFontSize {
  font-size: 1.2rem;
  color: #afafaf;
  text-align: center;
}
// @media only screen and (max-width: 600px) {
//   .ManagedServicesHeroText {
//     @include ManagedServicesHeroText;
//     padding: 150px 100px 0px 100px;
//   }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 500px) {
//   .ManagedServicesHeroText {
//     @include ManagedServicesHeroText;
//     padding: 120px 100px 0px 100px;
//     font-size: 1.2em;
//   }
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .ManagedServicesHeroText {
//     @include ManagedServicesHeroText;
//     padding: 150px 100px 0px 100px;
//     font-size: 1.5em;
//   }
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
//   .ManagedServicesHeroText {
//     @include ManagedServicesHeroText;
//     padding: 150px 100px 0px 100px;
//   }
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//   .ManagedServicesHeroText {
//     @include ManagedServicesHeroText;
//     padding: 150px 100px 0px 100px;
//   }
// }

// // style for portfolio hero begin

// .PortfolioHeroText {
//   color: $prybgcolor;
//   background-color: $heroblackbg;
//   height: 397px;
// }

.FoundersMeetupText {
  color: $sectextcolor !important;
}
.FoundersMeetupBg {
  color: $prytextcolor;
  background-color: $lightgreenbg;
}

.PortfolioHeroText {
  color: $sectextcolor !important;
}
.PortfolioHeroBg {
  color: $sectextcolor;
  background-color: $heroblackbg;
}
.porfolioParagraph {
  font-size: 1.3rem;
  line-height: 2rem;
}

.ReadMoreResourcesBg {
  background-color: #2400a4;
}
.talentHeroBg {
  background: rgba(0, 164, 149, 0.1);
}
