.eightGearImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/eightgear.png);
  background-size: cover;
  position: relative;
  height: 30rem;
  width: 100%;
}

.FUTAImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/futa.png);
  background-size: cover;
  position: relative;
  height: 30rem;
  width: 100%;
}

.sterlingImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/sterling.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}
.innkeeperImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/innkeeper.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}
.pearlMutualImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/pearlmutual.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}
.smedanImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/smedan.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.imgCardBg {
  bottom: 10px;
  //   width: 22rem;
  left: 50%;
  width: 20rem;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin-bottom: 0.7rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.keyBoxShadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
