@use "../../colors" as *;
.whyChooseUsBg {
  background-color: $lightgreenbg;
}
@mixin WhyChooseUsCardBg {
  border: 3px solid $darkgreenbg;
  border-radius: 60px;
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);
}
.whyChooseUsCardBg {
  @include WhyChooseUsCardBg;
}

@media only screen and (min-width: 768px) {
  .startStep {
    margin-top: 7.5rem;
  }
  .buildStep {
    margin-top: 3.75rem;
  }
  .scaleStep {
    margin-top: 0;
  }
}
