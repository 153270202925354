@use "../../colors" as *;
.cards {
    text-align: center;
}

.containerm {
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdvisoryImage {
    overflow: hidden;
    height: 300px;
    width: 300px;
    position: relative;
    cursor: pointer;
    margin: 0 15px;
    box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    background-color: #555;
}

.AdvisoryImage:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 500px;
    transform: translate(-140%, -50%);
    background-color: gray;
    opacity: 0.8;
    border-radius: 50%;
    transition: 0.8s;
}

.AdvisoryImage:hover:after {
    transform: translate(-50%, -50%);
}

.AdvisoryImage:hover .AdvisoryIcon {
    transform: translate(-50%, -50%) scale(1.3);
}

.AdvisoryIcon {
    position: absolute;
    height: 110%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.8s;
}

.AdvisoryName {
    position: absolute;
    text-align: center;
    line-height: 90%;
    font-size: small;
    font-weight: bold;
    z-index: 2;
    top: 75%;
    left: 50%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: 0.8s;
    transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryName {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}

.AdvisoryPosition {
    position: absolute;
    text-align: center;
    font-size: small;
    z-index: 2;
    top: 83%;
    left: 50%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: 0.8s;
    transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryPosition {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}

.AdvisoryLink {
    position: absolute;
    text-align: center;
    font-size: xx-small;
    z-index: 2;
    top: 92%;
    left: 50%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: 0.8s;
    transition-timing-function: ease-in;
}

.AdvisoryImage:hover .AdvisoryLink {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}

.ProjectDeliveryImage {
    overflow: hidden;
    height: 300px;
    width: 300px;
    position: relative;
    cursor: pointer;
    margin: 0 15px;
    box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    background-color: #555;
}

.ProjectDeliveryImage:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 500px;
    transform: translate(-140%, -50%);
    background-color: gray;
    opacity: 0.8;
    border-radius: 50%;
    transition: 0.8s;
}

.ProjectDeliveryImage:hover:after {
    transform: translate(-50%, -50%);
}

.ProjectDeliveryImage:hover .AdvisoryIcon {
    transform: translate(-50%, -50%) scale(1.3);
}
.ProjectDeliveryImage:hover .AdvisoryPosition {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}
.ProjectDeliveryName {
    position: absolute;
    text-align: center;
    line-height: 90%;
    font-size: small;
    font-weight: bold;
    z-index: 2;
    top: 75%;
    left: 50%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: 0.8s;
    transition-timing-function: ease-in;
}

.ProjectDeliveryImage:hover .ProjectDeliveryName {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}
.ProjectDeliveryLink {
    position: absolute;
    text-align: center;
    font-size: xx-small;
    z-index: 2;
    top: 92%;
    left: 50%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: 0.8s;
    transition-timing-function: ease-in;
}

.ProjectDeliveryImage:hover .ProjectDeliveryLink {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}
