@use "../../colors" as *;
.howItWorksBg {
  background-color: $lightgreenbg;
}

.cardss {
  border-width: 0em !important;
}

.cardsss {
  border-radius: 5px;
}
.managedServicesBg {
  background-color: $lightgreenbg;
}
.cardBgg {
  background-color: $prybgcolor;
}
