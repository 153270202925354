// .card {
//   fill: #fff;
//   stroke-width: 5px !important;
//   stroke: rgba(0, 0, 0, 0.8) !important;
// }

.fade-in {
  opacity: 1;
  transition: opacity 1s;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s;
}

.slider2 {
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slider2::before,
.slider2::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider2::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider2::before {
  left: 0;
  top: 0;
}

.slider2 .slide-track2 {
  -webkit-animation: scroll 45s linear infinite;
  animation: scroll 45s linear infinite;
  display: flex;
  width: fit-content;
}

.slider2 .slide2 {
  height: 100px;
  display: flex;
}

.slider2 .slide2 img {
  object-fit: contain;
  height: 100%;
  width: auto;
  margin: auto;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    /* scroll only 100% of the total width */
    transform: translateX(-50%);
  }
}
.talentPlacementCard {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 40px 40px;
}
