// .AnimationVideoHeroContainer {
//   height: 100%;
//   width: 100%;
// }

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  position: relative;
  height: 100vh;
  text-align: center;
  justify-content: center;
}

// .AnimationVideoHero {
//   height: 80vh;
//   width: 100%;
// }
// .play-button-centered {
//   position: absolute;
//   // left: 50%;
//   // top: 50%;
//   // right: 0%;
//   // bottom: 0%;
//   // color: #c1c1c1;
//   // transform: translate(-50%, -50%);
//   width: 40%;
// }

// // play-button-centered on small screen start
// @media screen and (max-width: 576px) {
//   .play-button-centered {
//     position: absolute;
//     left: 50%;
//     top: 20%;
//     right: 0%;
//     bottom: 0%;
//     color: #c1c1c1;
//     transform: translate(-50%, -50%);
//     width: 80px;
//     height: 80px;
//   }
// }

// // play-button-centered on medium screen start
// @media screen and (max-width: 768px) {
//   .play-button-centered {
//     position: absolute;
//     left: 50%;
//     top: 20%;
//     right: 0%;
//     bottom: 0%;
//     color: #c1c1c1;
//     transform: translate(-50%, -50%);
//     width: 80px;
//     height: 80px;
//   }
// }
