@use "../../colors" as *;
// @use "../font" as *;

.link {
  color: #ef00c0;
  text-align: center;
  line-height: 3px;
}
// .teamcard {
//   height: 200px;
//   width: 280px;
//   left: 50px;
//   right: 50px;
//   //   top: 100px;
//   //   bottom: 50px;
// }
.teamcardbody {
  border-radius: 0px;
  border-bottom: 5px solid $darkgreenbg !important;
  border-right: 5px solid $darkgreenbg !important;
  border-left: 5px solid $darkgreenbg !important;
}

.Teampic {
  display: block;
  width: 100%;
}
.Text {
  line-height: 1px;
  font-size: 0.9rem;
}
.titleText {
  font-size: 0.9rem;
}
// .Team {
//   margin-top: 50%;
// }
// .advisorcard {
//   //   margin-top: 40%;
//   //  margin-right: auto;
//   //   height: 200px;
//   //   width: 280px;
//   //   left: 50px;
//   //   right: 50px;
// }
// .Boardimage {
//   display: block;
//   width: 100%;
// }
