.faq {
  background-color: #00a495;
}

.Acc {
  color: black;
}

// .accordion-button:not(.collapsed)::after {
//   background-image: var(--bs-accordion-btn-active-icon);
//   transform: var(--bs-accordion-btn-icon-transform);
// }

// transform: var(--bs-accordion-btn-icon-transform)

// accordion-button:not(.collapsed)::after
