@use "../colors" as *;
@use "../font" as *;

// $breakpoint-tablet: 768px;
// @media (min-width: $breakpoint-tablet) {
// }
.navbar {
  margin: 8px;
  color: $sectextcolor;
}

.constructionpage {
  max-width: 100%;
  width: fit;
  // block-size: auto;
}
// .backsoon {
//   color: $btncolor;
// }
