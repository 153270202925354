@use "../../colors" as *;
@use "../../font" as *;

.OnHoverReadMoreBtn {
  // width: auto;
  // height: 30px;
  // background-color: #821e6b;
  // color: #ffffff;
  // font-size: 14px;
  // border-radius: 35px;
  // border: none;
  // border: 3px solid $btncolor;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 8.55rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

@mixin LearnMoreBtn {
  // border: 2.5px solid $btncolor;
  // color: $prytextcolor;
  // font-size: 25px;
  // font-weight: 500;
  // background-color: #fff;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 8.55rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.LearnMoreBtn {
  @include LearnMoreBtn;
  // border-radius: 2.187rem;
  // padding: 0.4rem 1.5rem;
}

.RequestForProposalBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 17rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.ProjectDeliveryViewMoreBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.RegisterBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
