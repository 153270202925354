@use "../../colors" as *;
.BorderOf1px {
  padding: 1px;
  border: 1px solid black;
  width: 106px;
}

.ProjectDeliveryManagedServicesCardText {
  font-size: 20px;
  color: white;
  font-weight: 900;
  margin-top: 100px;
}

.bg-pic1 {
  // width: 330px;
  height: 400px;
  background-size: cover;
}

.bg-logo1 {
  // width: 330px;
  height: 300px;
  // border: 1px solid black;
  // float: left;
}

.bg-logo2 {
  //background-image: url(../assets/images/logo.png);
  // width: 330px;
  height: 300px;
  background-size: cover;
  border: 1px solid black;
  // float: left;
}

.bg-logo3 {
  //background-image: url(../assets/images/logo.png);
  // width: 330px;
  height: 300px;
  background-size: cover;
  border: 1px solid black;
  // float: left;
}

.bg-logo4 {
  //background-image: url(../assets/images/logo.png);
  // width: 330px;
  height: 300px;
  background-size: cover;
  border: 1px solid black;
  // float: left;
}

.TextAlignCenter {
  text-align: center;
}

// .AllPortfoliosLogoCard {
//   border: none;
// }
// .gallerys4IMG_ {
//   width: 1rem;
// }

// .projectCardsimg {
//   height: 12.5rem;
// }
.AllPortfoliosLogoCard {
  border: none;
}
.successStoryCardBg {
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);
}
.galleryvideos {
  width: 5rem;
  max-height: 60rem;
}
.projectdeliveryCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 340px;
  background-color: #f7e1f7;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
}

// OUR IMPACT SECTION
.impactSection {
  margin-top: 1rem;
  margin-bottom: 4rem;
}
