.about-hero-background {
  background-image: url("../About/image/about-img.jpg");
  background-size: cover;
  background-position: center;
}

.about-layout {
  background: linear-gradient(to top, rgba(0, 0, 0), transparent);
  min-height: 100vh;
}

.heroAbout {
  margin-top: 300px;
}

.animated-text {
  font-size: 3rem;
  min-width: 280px;
}

@media (max-width: 425px) {
  .animated-text {
    font-size: 2.3rem;
  }
}
.homeHeroBig {
  position: relative;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
}

.homeHeroBig::before {
  content: "";
  background: url("../About/image/HomepageHero.jpeg");

  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.homeHeroBig h1 {
  position: relative;
}
.homeHeroBig h3 {
  position: relative;
}
.homeHeroBig p {
  position: relative;
}
.gradientBgg {
  background: rgba(0, 0, 0, 0.678);
}
.overlayBgg {
  position: absolute;
  height: 80vh;
  width: 100%;
  opacity: 6;
  top: 60;
  left: 0;
  margin-top: 8px;
}

.jj {
  background: linear-gradient(
    to bottom,
    rgb(196, 195, 195),
    rgb(255, 253, 253)
  );
}
.homeHeroSmall {
  position: relative;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.homeHeroSmall::before {
  content: "";
  /* background: url("../About/image/HomepageHero\ -\ Copy\ \(2\).jpeg"); */
  /* background: url("../About/image/HomepageHeroCopy.jpeg"); */
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
}

.homeHeroSmall h1 {
  position: relative;
}
.homeHeroSmall h3 {
  position: relative;
}
.homeHeroSmall p {
  position: relative;
}
.gradientBggg {
  background: rgba(0, 0, 0, 0.678);
}

.overlayBggg {
  position: absolute;
  height: 80vh;
  width: 100%;
  opacity: 6;
  top: 60;
  left: 0;
  margin-top: -15px;
}
/* CoreValue */
/* .valueCard {
  width: 320px !important;
  height: 430px;
} */

.core-accordion-button {
  height: 20px;
}

.core-accordion-button:focus {
  box-shadow: none !important;
}

@media (max-width: 1024px) {
  .CoreCard {
    margin-right: 30px;
  }
}

@media (max-width: 425px) {
  .CoreCard {
    margin-left: 30px;
  }
}

@media (max-width: 375px) {
  .CoreCard {
    margin-left: 30px !important;
  }
}

@media (max-width: 320px) {
  .CoreCard {
    margin-left: 10px;
  }
}

.accordion-button {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.accordion-body {
  font-size: 1rem;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
}

.accordion-button {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}

.accordion-body {
  font-size: 0.9rem;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
}

/* Slider Card Start*/
.slideCard {
  width: 267px !important;
}

.SliderBg {
  background-color: #f6fffa;
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}

.scroll-content {
  display: inline-block;
  animation: scrollSlider 40s linear infinite;
}

.scroll-card {
  display: inline-block;
  margin-right: 16px;
}

@keyframes scrollSlider {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Slider Card End*/

.ImgCard {
  height: 255px;
}

.bodyBG {
  background: #152425 !important;
}
