html,
body {
  overflow-x: hidden !important;
}

// HERO SECTION
.heroSection {
  height: 90%;
  width: 100vw !important;
  overflow-y: hidden !important;
  background-color: #f6f6f6;
}

.Left-section-text {
  position: relative;
  top: 0px !important;
  left: 0 !important;
  padding: 0.5rem;
}

.hero-pill {
  color: #5e8d70;
  background-color: #d6eed7;
  width: 200px;
  p {
    font-weight: bolder !important;
  }
}

.hero-caption {
  font-size: 2rem !important;
  width: 900px !important;
  text-wrap: wrap;
}
.hero-card {
  width: 900px !important;
}
.event-detail {
  font-weight: 100 !important;
  text-decoration: none;
  font-size: 20px;
}

.details {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;

  p {
    font-size: 20px;
  }
}

.dateicon {
  width: 50%;
  span {
    font-size: 20px;
    font-family: "Open Sans";
  }
}
.clockicon {
  width: 50%;
  align-items: center;
  display: flex;
  gap: 5px;
  align-items: baseline;
  span {
    font-size: 20px;
    font-family: "Open Sans";
  }
  p {
    font-size: 16px;
  }
}
.locationicon {
  width: 100%;
  span {
    font-size: 20px;
    font-family: "Open Sans";
  }
  p {
    font-size: 16px;
  }
}
// RIGHT SECTION
.hero-pic1 {
  position: absolute;
  top: 100px !important;
  right: 280px !important;
  height: 250px !important;
  z-index: 10;
  border-radius: 90px 0px 90px 0px !important;
}
.hero-circle {
  position: absolute;
  top: 200px !important;
  right: 180px !important;
  height: 180px !important;
  border-radius: 50% !important;
  box-shadow: -2px 5px 7px;
}

.hero-pic2 {
  position: absolute;
  top: 240px;
  height: 230px !important;
  right: 80px !important;
  border-radius: 0px 90px 0px 90px !important;
}

.body-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin: auto;
}
.registerContainer {
  height: 1450px;
  position: relative;
  right: 100px !important;
}

.registerpanel {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 20px !important;
  width: 350px !important;
  background-color: white;
  border-radius: 10px !important;
  z-index: 20;
  height: 150px !important;
  right: 150px !important;
  h2 {
    font-size: 25px;
    color: #920f84;
    font-weight: 800;
  }
  p {
    font-family: "Open Sans";
    font-weight: 300;
  }
}
.smRegisterLink {
  background-color: #920f84;
  padding: 10px;

  a {
    text-decoration: none;
    color: #f6f6f6;
    font-weight: bold;
    font-size: 12px;
  }
}

.registercontent {
  padding: 0;
}
// SMALL HERO
.small-hero {
  display: none;
}
// SECTION 2
.no-list {
  font-weight: 500 !important;
  text-decoration: none !important;
  list-style: disc;
  margin: 0;
}

.speaker-detail {
  width: 210px;
  height: 250px;
  border: 1px solid #920f84;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 50px 0px 50px 0px !important;
  margin: 15px;
  padding: 2px;
}
.speaker-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    color: black !important;
    font-weight: 100;
    text-decoration: underline;
    line-height: 20px !important;
    font-size: 16px;
    padding: 5px;
  }
  p {
    color: #adadad;
    line-height: 5px;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  a {
    color: #000000;
    line-height: 10px;
    font-size: 12px;
    text-align: center;
  }
}
.speaker-img {
  position: relative;
  width: 60% !important;
  height: 50% !important;
  padding: 10px;
}

.row1 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.row2 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

// MEDIA QUERY
// XLarge Screens
@media (min-width: 1440px) and (max-width: 2580px) {
  .hero-caption {
    width: 700px !important;
  }
  .hero-card {
    width: 600px !important;
  }
}
/* Large Screens */
@media (min-width: 1024px) and (max-width: 1350px) {
  .heroSection {
    height: 80% !important;
  }

  .hero-caption {
    font-size: 20px !important;
    width: 400px !important;
    padding: 10px !important;
  }
  .hero-pill {
    font-size: 12px;
  }
  .hero-subcaption {
    padding-top: 1px !important;
    padding-bottom: 2px !important;
  }
  // LEFT SECTION
  .hero-card {
    width: 500px !important;
    height: 180px !important;
    font-size: 10px;
  }

  .event-detail {
    font-weight: bold !important;
    font-size: 17px;
  }

  .details {
    gap: 2px;
    p {
      font-size: 15px;
    }
  }

  .dateicon {
    width: 55%;
    span {
      font-size: 16px;
      font-weight: bold;
      font-family: "Open Sans";
    }
  }
  .clockicon {
    width: 55%;
    gap: 2px;
    span {
      font-size: 16px;
      font-weight: bold;
      font-family: "Open Sans";
    }
    p {
      font-size: 14px;
    }
  }

  .event-icon {
    width: 15px !important;
  }

  // RIGHT SECTION
  .hero-pic1 {
    position: absolute;
    top: 100px !important;
    right: 280px !important;
    height: 250px !important;
  }
  .hero-circle {
    position: absolute;
    top: 200px !important;
    right: 180px !important;
    height: 180px !important;
  }

  .hero-pic2 {
    position: absolute;
    top: 240px;
    height: 230px !important;
    right: 80px !important;
  }
  .registerpanel {
    position: fixed;
    top: 450px;
    width: 350px !important;
  }
  //SPEAKER
  .speaker {
    width: 500px;
  }
  // SMALL HERO
  .small-hero {
    display: none;
  }
}

/* Medium devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 1020px) {
  .hero-caption {
    font-size: 18px !important;
    width: 300px !important;
    padding: 5px !important;
  }
  .hero-pill {
    font-size: 12px;
  }
  .hero-subcaption {
    font-size: 16px !important;
    padding-top: 1px !important;
    padding-bottom: 2px !important;
  }
  // LEFT SECTION
  .hero-card {
    width: 350px !important;
    height: 180px !important;
    font-size: 10px;
  }

  .event-detail {
    font-weight: bold !important;
    font-size: 10px;
  }

  .details {
    gap: 2px;
    p {
      font-size: 12px;
    }
  }

  .dateicon {
    width: 55%;
    span {
      font-size: 12px;
      font-weight: bold;
      font-family: "Open Sans";
    }
  }
  .clockicon {
    width: 55%;
    gap: 2px;
    span {
      font-size: 12px;
      font-weight: bold;
      font-family: "Open Sans";
    }
    p {
      font-size: 12px;
    }
  }

  .locationicon {
    width: 100%;
    span {
      font-size: 12px;
      font-family: "Open Sans";
    }
    p {
      font-size: 12px;
    }
  }

  .event-icon {
    width: 12px !important;
  }
  // RIGHT SECTION
  .hero-pic1 {
    position: absolute;
    top: 100px !important;
    right: 210px !important;
    height: 230px !important;
  }
  .hero-circle {
    position: absolute;
    top: 250px !important;
    right: 150px !important;
    height: 120px !important;
  }

  .hero-pic2 {
    position: absolute;
    top: 250px;
    height: 200px !important;
    right: 30px !important;
  }
  .registerContainer {
    display: none !important;
  }
  .registerpanel {
    position: fixed;
    display: none !important;
    top: 200px;
    right: 10% !important;
    width: 250px !important;
  }
  .registerpanelsm {
    display: block;
    width: 400px !important;
    margin: 10px;
    text-align: center !important;
    border-radius: 20px !important;
    h2 {
      color: #920f84;
    }
    // SPEAKERS
    .speaker {
      width: 500px !important;
    }
  }
  // SMALL HERO
  .small-hero {
    display: none !important;
  }
  .smRegisterLink {
    display: block !important;
    position: absolute;
    top: 400px !important;
    left: 20px !important;
    margin: 10px;
    height: 40px;
    a {
      font-size: 14px;
    }
  }
  .speaker-detail {
    width: 300px;
    height: 240px;
  }
  .row1 {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 500px;
  }
  .row2 {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 500px;
  }
}

/* Small devices (phones, less than 576px) */
@media (min-width: 300px) and (max-width: 575.98px) {
  .heroSection {
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 3px;
  }
  .Left-section-text {
    width: 100% !important;
    font-size: 14px !important;
    text-align: center;
  }
  .hero-caption {
    font-size: 20px !important;
    word-wrap: break-word;
    margin: auto !important;
    text-align: center;
    width: 280px !important;
    justify-content: start !important;
  }
  .hero-subcaption {
    font-size: 16px !important;
    padding-top: 1px !important;
    padding-bottom: 2px !important;
  }
  // RIGHT SECTION
  // LEFT SECTION
  .hero-card {
    width: 300px !important;
    height: 180px !important;
    margin: auto !important;
    font-size: 10px;
  }

  .event-detail {
    font-weight: bold !important;
    //font-size: 15px;
  }

  .details {
    gap: 2px;
    p {
      font-size: 12px;
    }
  }

  .dateandTime {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .dateicon {
    width: 100%;
    span {
      font-size: 12px;
      font-weight: bold;
      font-family: "Open Sans";
    }
    justify-content: center;
  }
  .clockicon {
    width: 100%;
    gap: 2px;
    span {
      font-size: 12px;
      font-weight: bold;
      font-family: "Open Sans";
    }
    p {
      font-size: 12px;
    }
    justify-content: center;
  }

  .locationicon {
    width: 100%;
    span {
      font-size: 12px;
      font-family: "Open Sans";
    }
    p {
      font-size: 12px;
    }
  }

  .event-icon {
    width: 12px !important;
  }
  // RIGHT SECTION
  .hero-pic1 {
    display: none;
  }
  .hero-circle {
    display: none;
  }

  .hero-pic2 {
    display: none;
  }
  // SMALL HERO
  .small-hero {
    display: flex !important;
    width: 100% !important;
    margin: 20px;
  }
  .smRegisterLink {
    display: block !important;
    background-color: #920f84;
    padding: 10px;

    a {
      text-decoration: none;
      color: #f6f6f6;
      font-weight: bold;
      font-size: 12px;
    }
  }

  // .event-content {
  //     margin: 0 !important;
  // }
  .row1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .row2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .speaker-detail {
    width: 280px !important;
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .registerpanel {
    display: none !important;
  }
  .registerpanelsm {
    display: block;
    width: 300px !important;
    background-color: white;
    border-radius: 10px !important;
    z-index: 20;
    padding: 10px;
    h2 {
      font-family: "Rubik", "sans-serif";
      font-size: 22px;
      font-weight: 800;
      color: #920f84;
    }
    p {
      font-family: "Open Sans";
      font-weight: 300;
    }
  }
  .section-two {
    h3 {
      // color: black !important;
      font-size: 22px;
      padding: 3px !important;
    }
  }
  .event-section {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .event-content {
    text-align: left;
    justify-content: center !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
  .speaker {
    width: 100% !important;
  }
  .speaker-detail {
    width: 200px !important;
    height: 220px;
  }
  .row1 {
    align-items: center;
  }
  .row2 {
    align-items: center;
  }
}
