@use "../../colors" as *;
@use "../../font" as *;

.BookNowBtn {
  // width: auto;
  // height: 30px;
  // background-color: #821e6b;
  // color: #ffffff;
  // font-size: 14px;
  // border-radius: 35px;
  // border: none;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 10rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.team-button-color {
  color: $btncolor;
  &:hover {
    color: $btncolor;
    font-size: 1.1rem;
  }
}
.BookaSessionBtn {
  // width: auto;
  // height: 30px;
  // background-color: #821e6b;
  // color: #ffffff;
  // font-size: 14px;
  // border-radius: 35px;
  // border: none;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.BookaFreeSessionBtn {
  // width: auto;
  // height: 30px;
  // background-color: #821e6b;
  // color: #ffffff;
  // font-size: 14px;
  // border-radius: 35px;
  // border: none;
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 12rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.JointheStudioBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 14rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
// .JointheStudioBtn:hover {
//   background-color: $btncolor;
//   color: #fff;
// }

// &:focus {
//   background-color: $btncolor;
//   color: $sectextcolor;
// }
//  &:hover {
//   background: #724598;
//   color: #ffffff;
// }

// &:focus {
//   background: #724598;
//   color: #ffffff;
// }

@mixin JointheTeamBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 10rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.JointheTeamBtn {
  @include JointheTeamBtn;
}
@mixin SubmitFormBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 7rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}

.SubmitFormBtn {
  @include SubmitFormBtn;
}

.HomeBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 6rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.ContactUsBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
.DigiPlusRequestBtn {
  border: 3px solid $btncolor;
  color: $prytextcolor;
  border-radius: 20px;
  width: 9rem;
  height: 2.5rem;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: $btncolor;
    color: $sectextcolor;
  }
  &:focus {
    background-color: $btncolor;
    color: $sectextcolor;
  }
}
