.resourcesNew {
    background-color: #F3F3F3;
}

.resourcesNew .button-custom {
    background-color: white;
    width: 15rem;
    color: #A5009F;
    border-radius: 2rem ;
    border: 2px solid #A5009F;
}

.resourcesNew .button-active{
    background-color: #A5009F;
    color: white;
}


.desktop-responsive-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    margin: 0px auto;
}

.desktop-responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 1rem;
  }